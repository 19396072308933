import { actionsType } from '../actions/localstorageActions';

const DEFAULT_STATE = {
    userFilters: {}, // Qui la chiave rispecchia direttamente il contenuto
}

const setLocalStorageFilters = (state, action) => {
    //console.warn('userFilters => ', action.payload)
    return {
        ...state,
        userFilters: action.payload, // Aggiorna i filtri utente con il payload
    }
}
const handlers = {
    [actionsType.SET_LOCAL_STORAGE_FILTERS_SUCCESS]: setLocalStorageFilters,
}

export default function localStorageReducer(state = DEFAULT_STATE, action) {
    if (handlers.hasOwnProperty(action.type)) {
        return handlers[action.type](state, action)
    } else {
        return state
    }
}
