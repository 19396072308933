import { put } from 'redux-saga/effects'
import { setFilterValue } from '../../actions/filtersActions'
import { emitSagaEvent } from '../sharedChannel';
import * as constants from '../../constants'
import { removeFilterFromLocalStorage } from '../../utils/LocalStorageFilterUtils';


function* loadFullTextFilterSaga(storedFilters) {
    emitSagaEvent('saga_start')

    const storedQFilters = storedFilters.q
    const storeMSearchFilters = storedFilters.m_search
    if(
        (storedQFilters === undefined || storedQFilters === null || storedQFilters.length === 0)
        &&
        (storeMSearchFilters === undefined || storeMSearchFilters === null || storeMSearchFilters.length === 0)
    ) {
        emitSagaEvent('saga_end')
        return
    }

    const filterValue = storedQFilters ?? storeMSearchFilters

    yield put(setFilterValue(constants.FULL_TEXT_FILTER, filterValue,
        storedQFilters ? {mode:'legacy'} : {mode:'elastic'}))

    removePreviousStoredTextFullFilter(storedQFilters ? 'q' : 'm_search')

    emitSagaEvent('saga_end')
}
function* removeFullTextFilter(key, storedFilters) {

    if(!storedFilters['q'] && !storedFilters['m_search']) return

    removeFilterFromLocalStorage('q')
    removeFilterFromLocalStorage('m_search')

    yield put(setFilterValue(key, ''))
}

const removePreviousStoredTextFullFilter = (actualKey) => {
    if(actualKey === 'q') {
        removeFilterFromLocalStorage('m_search')
    }

    if(actualKey === 'm_search') {
        removeFilterFromLocalStorage('q')
    }
}
export { loadFullTextFilterSaga, removeFullTextFilter, removePreviousStoredTextFullFilter }