import React, { Component } from 'react'
import { withI18n } from '@lingui/react'
import sortBy from 'lodash/sortBy'
import includes from 'lodash/includes'
import forOwn from 'lodash/forOwn'
import get from 'lodash/get'
import cx from 'classnames'
import * as Accordion from '@radix-ui/react-accordion'
import { FiPlus, FiX } from 'react-icons/fi'

import * as c from '../constants'
import ItemDataCategory from './ItemDataCategory'
import ErrorBoundary from './utils/ErrorBoundary'
import { getCategoryButtonStatus } from '../helpers'
import { getLocaleFromLanguageCode } from '../intl-helpers'
import find from 'lodash/find';
import { selectors as userSelectors } from '../reducers/userReducer';
import { connect } from 'react-redux';
import { compose } from 'redux';

const categories = Object.values(c.ARTICLE_DETAIL_SECTIONS)

export class ArticleDetailsCategoryTab extends Component {
    render() {
        const {
            activeCategories,
            details,
            productProperties,
            etimClassDetails,
            article,
            language,
            i18n,
        } = this.props

        const locale = getLocaleFromLanguageCode(language)

        // Here we add the pinaxo as extra category
        let extraCategories = []

        const categoryKeys = categories.map((c) => c.key)

        forOwn(details, (v, k) => {
            if (!includes(categoryKeys, k) && !includes(c.EXTRA_CATEGORIES_BLACKLIST_KEYS, k)) {
                if (Object.keys(v).length > 0) {
                    let key = k

                    extraCategories.push({
                        key,
                        order: 99,
                        type: 'standard',
                    })
                }
            }
        });

        const finalCategories = [].concat(...categories).concat(...extraCategories)

        // Remove everprice category if user haven't the proper grant or is empty
        // Get user everprice grants
        let everpriceGrants = null
        if(this.props.user){
            everpriceGrants = find(this.props.user.grants, { code: 'view_integrations' })
        }else{
            everpriceGrants = null
        }


        // Check if user have everprice_prices grant
        const everpricePricesGrant = everpriceGrants
            ? everpriceGrants.parameters.allowed_integrations.includes('everprice_prices')
            : false

        // Remove from categories the integrations category
        const finalCategoriesNoEverprice = finalCategories.filter(
            (category) => category.key !== 'integrations'
        )
        // Determine the final categories based on everprices grants and if everprices has values
        const finalCategoriesFiltered =
            everpricePricesGrant &&
            typeof details.integrations === "object" &&
            Object.keys(details.integrations ?? {}).length > 0
                ? finalCategories
                : finalCategoriesNoEverprice


        const categoryButtonStatus = getCategoryButtonStatus(details)

        return (
            <div className="col-span-12">
                <Accordion.Root type="multiple" collapsible={true} value={activeCategories}>
                    {sortBy(finalCategoriesFiltered, 'order')
                        .filter((category) => categoryButtonStatus[category.key] !== false)
                        .map((category) => {

                            const isOpen = includes(activeCategories, category.key)
                            const isDisabled = categoryButtonStatus[category.key] === false

                            return (
                                <Accordion.Item
                                    value={category.key}
                                    key={category.key}
                                    disabled={isDisabled}
                                >
                                    <Accordion.Header asChild={true}>
                                        <div
                                            className={cx('text-l border-b select-none', {
                                                'text-primary': !isDisabled,
                                                'text-gray-500': isDisabled,
                                            })}
                                        >
                                            <Accordion.Trigger
                                                asChild={true}
                                                onClick={() =>
                                                    !isDisabled &&
                                                    this.props.onTabClick(category.key)
                                                }
                                            >
                                                <div
                                                    className={cx('flex items-center', {
                                                        'cursor-pointer': !isDisabled,
                                                    })}
                                                >
                                                    {isOpen ? <FiX /> : <FiPlus />}
                                                    <span className="ml-3">
                                                        {get(
                                                            productProperties,
                                                            `${category.key}.label.${locale}`,
                                                            i18n._(category.label)
                                                        )}
                                                        {/* <Trans id={category.label} /> */}
                                                    </span>
                                                </div>
                                            </Accordion.Trigger>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Content>
                                        <ErrorBoundary>
                                            <ItemDataCategory
                                                productProperties={productProperties}
                                                etimClassDetails={etimClassDetails}
                                                item={article}
                                                details={details}
                                                category={category}
                                                language={language}
                                            />
                                        </ErrorBoundary>
                                    </Accordion.Content>
                                </Accordion.Item>
                            );
                        })}
                </Accordion.Root>
            </div>
        );
    }
}

ArticleDetailsCategoryTab.defaultProps = {
    scrollOnClick: false,
}

function mapStateToProps(state) {
    return {
        user: userSelectors.getUser(state),
    }
}

export default compose(connect(mapStateToProps), withI18n())(ArticleDetailsCategoryTab)
