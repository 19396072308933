import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import * as filterSelectors from '../../selectors/filterSelectors';
import * as constants from '../../constants';
import { actions as filtersActions, actions, actionTypes } from '../../actions/filtersActions';
import { getApiPayload } from '../../selectors/filterPayload';
import * as api from '../../api';
import { getIsLoadingFromLocalStorage } from '../../reducers/pageStateReducer';
import { fetchFilterData, refreshFiltersValues } from '../filtersSaga';
import { brandDependentFilters } from '../../utils/filtersUtils';
import { getFilter } from '../../selectors/filterSelectors';

export function* changeBrandFilterSaga(action) {
    const actualBrandFilter = yield select(filterSelectors.getFilter, constants.BRAND_FILTER);

    const actualBrandFilterValue = actualBrandFilter?.value?.toJS() || [];

    let isValueInFilter = false;

    if (action.payload !== null) {
        isValueInFilter = actualBrandFilterValue.some(
            (filter) => filter?.value === action.payload.value
        )
    }

    // Case 1: The value is not present, add it and reset dependent filters
    if (!isValueInFilter && action.payload !== null) {
        //console.log("The value is not present, resetting brand-dependent filters");

        //*** moving into localstorage saga related subsaga
        //removeBrandRelatedFilterFromLocalStorage()
        //***

        yield put(actions.resetFilters(brandDependentFilters))

        // Case 2: The value is already present and is the only element: deselecting the last active filter
    } else if (actualBrandFilterValue.length === 1 && isValueInFilter || action.payload === null) {
        //console.log("The value is present and is the only one, resetting brand-dependent filters");

        yield put(actions.resetFilters(brandDependentFilters));

        // I get with selectors FULL_TEXT_FILTER to check if has value. I will reset all filters only if have no value
        const fullTextFilter = yield select(getFilter, constants.FULL_TEXT_FILTER)
        if(fullTextFilter?.value === '') {
            yield put(filtersActions.resetAllFilters());
        }

        yield put(actions.setFilterValue(constants.BRAND_FILTER, null));

        refreshFiltersValues()

        return

        // Case 3: The value is present but is not the only one, toggle without resetting
    } else {
        // console.log("The value is present, acting as a toggle without reset");
    }

    yield put(actions.setFilterValue(constants.BRAND_FILTER, action.payload));

    const updateFilterAction =
        action.payload === null ? actions.disableFilters : actions.enableFilters;

    yield put(updateFilterAction(brandDependentFilters))
    const apiPayload = yield select(getApiPayload)

    const shouldFetchFilters = (actualBrandFilterValue.length === 0) ||
        (actualBrandFilterValue.length === 2 && apiPayload.sigle_marca.toJS().length === 1)


    if (shouldFetchFilters) {

        yield all([
            call(fetchFilterData, api.fetchLineeFilter, apiPayload, constants.LINE_FILTER),
            call(fetchFilterData, api.fetchModelliFilter, apiPayload, constants.MODEL_FILTER),
            call(fetchFilterData, api.fetchSeriesFilter, apiPayload, constants.SERIES_FILTER),
            call(fetchFilterData, api.fetchListini, apiPayload, constants.LISTINI_FILTER)
        ])
        // refreshFiltersValues()
    }
    const isLoadingFromLocalStorage = yield select(getIsLoadingFromLocalStorage)

    if(apiPayload.etim_class !== undefined && !isLoadingFromLocalStorage) {
        yield call(fetchFilterData, api.fetchEtimFeatures, apiPayload, 'etim_features')
    }

}

export default [
    takeEvery(actionTypes.CHANGE_BRAND_FILTER_VALUE, changeBrandFilterSaga),
]