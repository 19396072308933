import { getFileName, getFilteredAssetsAttributes, logDownload } from './ItemDataCategoryTableUtils';
import { Trans } from '@lingui/react';
import filesize from 'filesize';
import LimitText from '../../utils/LimitText';
import SortableTableHeader from '../../utils/SortableTableHeader';
import React from 'react';

const RenderImageList = ({
     data,
     user,
     stateSortBy,
     stateSortDirection,
     toggleSort,
     getSorting}) => {
    const {
        onClick,
        showPreview,
        category,
        // type
        // toggleItem,
        // selected
    } = data;

    const isNotMobile = window.innerWidth >= 640;

    if (showPreview) {
        return (
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 pt-2">
                {getFilteredAssetsAttributes(data, stateSortBy, stateSortDirection).map((attr, i) => {
                    // console.log(attr);

                    return (
                        <div key={i} className="p-2 bg-slate-200 rounded">
                            <div className="flex items-center cursor-pointer min-h-[140px]">
                                <img
                                    className="img-responsive"
                                    // src={this.getFileThumbnailPath(attr.filename)}
                                    src={attr.src}
                                    alt={attr.label}
                                    onClick={() => onClick(i)}
                                    style={{
                                        maxHeight: '140px',
                                        margin: 'auto auto',
                                    }}
                                />
                            </div>
                            <div className="m-0">
                                <div
                                    className="text-primary"
                                    title={getFileName(attr.filename)}
                                    style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {getFileName(attr.filename)}
                                </div>
                                <div className="flex">
                                    <div className="grow">
                                            <span>
                                                <Trans id="ext" />: {attr.ext}
                                                <br />
                                                {attr.size && (
                                                    <>
                                                        <Trans id="weight" />:{' '}
                                                        {filesize(attr.size, {
                                                            separator: ',',
                                                            base: 10,
                                                        })}
                                                    </>
                                                )}
                                            </span>
                                    </div>
                                    <div>
                                        <a
                                            className="btn btn-primary"
                                            title="Download"
                                            href={attr.src}
                                            onClick={() => logDownload(data, user, attr.filename)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <i className="icon icon-download" />
                                        </a>
                                    </div>
                                </div>
                                <div className="text-bold">
                                    <LimitText
                                        limit={20}
                                        text={attr.description !== '-' ? attr.description : ''}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    if (isNotMobile) {
        return (
            <table className="table table-striped table-striped-alt table-hover table-dense">
                <thead>
                <tr>
                    {/* {type === constants.SECTION_TYPE_IMAGES && (
                            <th style={{ width: '52px' }} />
                            )} */}
                    <SortableTableHeader
                        attr="label"
                        onClick={toggleSort}
                        sorting={getSorting}
                        headerStyle={{ width: '310px' }}
                    >
                        <Trans id={category.classLabel} />
                    </SortableTableHeader>
                    {/* <SortableTableHeader
                                attr="ext"
                                onClick={this.toggleSort}
                                sorting={this.getSorting()}
                                headerStyle={{ width: '80px' }}
                            >
                                <Trans id="ext" />
                            </SortableTableHeader>
                            <SortableTableHeader
                                attr="size"
                                onClick={this.toggleSort}
                                sorting={this.getSorting()}
                                headerStyle={{ width: '80px' }}
                            >
                                <Trans id="weight" />
                            </SortableTableHeader> */}
                    <SortableTableHeader
                        attr="filename"
                        onClick={toggleSort}
                        sorting={getSorting}
                        headerStyle={{ width: '200px' }}
                    >
                        <Trans id="file:name" />
                    </SortableTableHeader>
                    <SortableTableHeader
                        attr="description"
                        onClick={toggleSort}
                        sorting={getSorting}
                        headerStyle={{ width: '380px' }}
                    >
                        <Trans id="description" />
                    </SortableTableHeader>
                    <th style={{ width: '120px' }} className="text-center">
                        <Trans id="download" />
                    </th>
                </tr>
                </thead>
                <tbody>
                {getFilteredAssetsAttributes(data, stateSortBy, stateSortDirection).map((attr, i) => (
                    <tr key={i}>
                        {/* {type === constants.SECTION_TYPE_IMAGES && (
                                <td>
                                    <span
                                        className="btn btn-action btn-link"
                                        onClick={() => onClick(i)}
                                    >
                                        <i className="icon icon-search" />
                                    </span>
                                </td>
                                )} */}
                        <td>
                            <LimitText
                                limit={30}
                                text={attr.label}
                                textClass="tooltip-right"
                            />
                        </td>
                        {/* <td>{attr.ext}</td>
                                <td>
                                    {attr.size
                                        ? filesize(attr.size, {
                                              separator: ',',
                                              base: 10
                                          })
                                        : '-'}
                                </td> */}
                        <td>
                            <LimitText limit={40} text={getFileName(attr.filename)} />
                        </td>
                        <td>
                            <LimitText limit={100} text={attr.description} />
                        </td>
                        <td className="text-center">
                            <a
                                className="btn btn-action btn-link"
                                title="Download"
                                href={attr.src}
                                onClick={() => logDownload(data, user, attr.filename)}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="icon icon-download" />
                            </a>
                            {/* <span className="form-group">
                                        <label className="form-checkbox">
                                            <input
                                                type="checkbox"
                                                checked={includes(
                                                    selected,
                                                    attr.key
                                                )}
                                                onChange={() =>
                                                    toggleItem(attr.key)
                                                }
                                            />
                                            <i className="form-icon" />
                                        </label>
                                    </span> */}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        );
    }

    return (
        <table className="table table-striped table-striped-alt table-hover table-dense">
            <thead>
            <tr>
                <th style={{ width: '120px' }} className="text-center">
                    <Trans id="download" />
                </th>
                <SortableTableHeader
                    attr="filename"
                    onClick={toggleSort}
                    sorting={getSorting()}
                    headerStyle={{ width: '420px' }}
                >
                    <Trans id="file:name" />
                </SortableTableHeader>
                <SortableTableHeader
                    attr="label"
                    onClick={toggleSort}
                    sorting={getSorting}
                    headerStyle={{ width: '310px' }}
                >
                    <Trans id={category.classLabel} />
                </SortableTableHeader>
                <SortableTableHeader
                    attr="ext"
                    onClick={toggleSort}
                    sorting={getSorting}
                    headerStyle={{ width: '80px' }}
                >
                    <Trans id="ext" />
                </SortableTableHeader>
                <SortableTableHeader
                    attr="size"
                    onClick={toggleSort}
                    sorting={getSorting}
                    headerStyle={{ width: '80px' }}
                >
                    <Trans id="weight" />
                </SortableTableHeader>
            </tr>
            </thead>
            <tbody>
            {getFilteredAssetsAttributes(props, stateSortBy, stateSortDirection).map((attr, i) => (
                <tr key={i}>
                    <td className="text-center">
                        <a
                            className="btn btn-action btn-link"
                            title="Download"
                            href={attr.src}
                            onClick={() => logDownload(data, user, attr.filename)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i className="icon icon-download" />
                        </a>
                    </td>
                    <td>
                        <LimitText limit={60} text={getFileName(attr.filename)} />
                    </td>
                    <td>
                        <LimitText limit={30} text={attr.label} textClass="tooltip-right" />
                    </td>
                    <td>{attr.ext}</td>
                    <td>
                        {attr.size
                            ? filesize(attr.size, {
                                separator: ',',
                                base: 10,
                            })
                            : '-'}
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    )
}

export default RenderImageList