import PinaxoAccordion from './Pinaxo/PinaxoAccordion'

const RenderPinaxo = ({data, user, itemData}) => {

    const catalogues = data
        .filter( (item) => item.type === 'C')
        .sort((a, b) => a.description.localeCompare(b.description))

    const listini = data
        .filter((item) => item.type === 'L')
        .sort((a, b) => a.description.localeCompare(b.description))

    const productCatalogues = data
        .filter((item) => item.type === 'C' && item.match)
        .sort((a, b) => a.description.localeCompare(b.description))

    const productListini = data
        .filter((item) => item.type === 'L' && item.match)
        .sort((a, b) => a.description.localeCompare(b.description))

    // console.warn('productCatalogues => ', productCatalogues, catalogues)
    // console.warn('productListini => ', productListini, listini)

    return (
        <div className="mx-auto px-2">
            {
                productCatalogues.length > 0 && (
                    <>
                        <PinaxoAccordion
                            title="Cataloghi Fotografici Pinaxo con il Prodotto"
                            items={productCatalogues}
                            user={user}
                            itemData={itemData}
                        />
                        <hr />
                    </>
                )
            }
            {
                catalogues.length > 0 && (
                    <>
                        <PinaxoAccordion
                            title="Cataloghi Fotografici Pinaxo del Marchio"
                            items={catalogues}
                            user={user}
                            itemData={itemData}
                        />
                        <hr />
                    </>
                )
            }

            {
                productListini.length > 0 && (
                    <>
                        <PinaxoAccordion
                            title="Listini prezzi Pinaxo con il
                            Prodotto"
                            items={productListini}
                            user={user}
                            itemData={itemData}
                        />
                        <hr/>
                    </>
                )
            }
            {
                listini.length > 0 && (
                    <>
                        <PinaxoAccordion
                            title="Listini prezzi Pinaxo del Marchio"
                            items={listini}
                            user={user}
                            itemData={itemData}
                        />
                        <hr />
                    </>
                )
            }
        </div>
    )
}

export default RenderPinaxo;