import * as Accordion from '@radix-ui/react-accordion'
import ErrorBoundary from '../../../utils/ErrorBoundary';
import { FiPlus, FiX } from 'react-icons/fi';
import React from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import { logAnalytic } from '../../../../api';
import { getLocaleFromLanguageCode } from '../../../../intl-helpers';

const PinaxoAccordion = (
    {title, items, user, itemData}
) => {

    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <Accordion.Root
            type="single"
            collapsible={true}
            onValueChange={(value) => setIsOpen(value === "productCatalogues.Pinaxo")}
        >
            <Accordion.Item value="productCatalogues.Pinaxo">
                <Accordion.Header asChild={true}>
                    <Accordion.Trigger>
                        <div
                            className={cx('flex items-center text-primary')}
                            onClick={() => setIsOpen(true)}
                        >
                            {isOpen ? <FiX /> : <FiPlus />}
                            <span className="text-primary pt-0 ml-3" key="cataloghi-prodotto">{title}</span>
                        </div>
                    </Accordion.Trigger>
                </Accordion.Header>
                <Accordion.Content>
                    <ErrorBoundary>
                        <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 pt-3 pb-3 pl-4">
                            {
                                items.map((item) => {
                                    return (
                                        <a
                                            key={item.document}
                                            href={item.document}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="relative flex flex-col items-center justify-between h-full group text-primary hover:no-underline"
                                            onClick={async (e) => {
                                                e.preventDefault()
                                                try {
                                                    await logPinaxo(user, item.description, itemData)
                                                } catch (error) {
                                                    console.error("Errore nel logging:", error);
                                                }

                                                window.open(item.document, "_blank", "noopener,noreferrer")
                                            }}
                                        >
                                            {/* pseudo element ::before  */}
                                            <div
                                                className="absolute inset-0 z-0 before:absolute before:inset-0 before:bg-primary before:opacity-0 before:transition-opacity before:duration-300 before:content-[''] group-hover:before:opacity-100  group-hover:scale-105"
                                            ></div>
                                            <div className="relative z-10 flex-grow flex items-center justify-center">
                                                <img
                                                    src={item.cover}
                                                    alt={item.description}
                                                    className="max-h-48 object-contain transition-transform duration-300 group-hover:scale-105"
                                                />
                                            </div>
                                            {/* Testo */}
                                            <div className="relative z-10 mt-4 text-center group-hover:text-white">
                                                <p className="text-xs mb-0">{item.description}</p>
                                                {/*<small>{item.release}</small>*/}
                                            </div>
                                        </a>
                                    );
                                })
                            }
                        </div>
                    </ErrorBoundary>
                </Accordion.Content>
            </Accordion.Item>
        </Accordion.Root>
    )
}

const logPinaxo = (user, description, data) => {

    if (!user || Object.keys(user).length === 0) return false;

    const { articleDetails } = data

    const locale = getLocaleFromLanguageCode(data.i18n.language)

    // logDownload(data, user, description)
    const logData = {
        utente: `${user.nome} ${user.cognome}`,
        brand: get(articleDetails, `identificazione.sigla_marca.value.label.${locale}`),
        acronym: get(articleDetails, 'identificazione.sigla_marca.value.code'),
        code: get(articleDetails, 'identificazione.codice_articolo.value'),
        code_description: get(
            articleDetails,
            `identificazione.descrizione_articolo.value.${locale}`
        ),
        operation: 'Download Pinaxo',
        subject: description,
    };
    // console.warn('logData => ', logData)
    logAnalytic(logData)
}

export default PinaxoAccordion