import SortableTableHeader from '../../utils/SortableTableHeader';
import { Trans } from '@lingui/react';
import { getFileName, getFilteredAssetsAttributes, logDownload } from './ItemDataCategoryTableUtils';
import LimitText from '../../utils/LimitText';
import React from 'react';

const RenderFileList = ({
    data,
    stateSortBy,
    stateSortDirection,
    user,
    toggleSort,
    getSorting
}) => {
    return (
        <table className="table table-striped table-striped-alt table-hover table-dense">
            <thead>
            <tr>
                <SortableTableHeader
                    attr="label"
                    onClick={toggleSort}
                    sorting={getSorting}
                    headerStyle={{ width: '312px' }}
                >
                    <Trans id={data.category.classLabel} />
                </SortableTableHeader>
                <SortableTableHeader
                    attr="filename"
                    onClick={toggleSort}
                    sorting={getSorting}
                    headerStyle={{ width: '200px' }}
                >
                    <Trans id="file:name" />
                </SortableTableHeader>
                <SortableTableHeader
                    attr="description"
                    onClick={toggleSort}
                    sorting={getSorting}
                    headerStyle={{ width: '380px' }}
                >
                    <Trans id="description" />
                </SortableTableHeader>
                {/* <th style={{ width: '200px' }}>
                            <Trans id="file:name" />
                        </th>
                        <th style={{ width: '380px' }}>
                            <Trans id="description" />
                        </th>*/}
                <th style={{ width: '120px' }} className="text-center">
                    <Trans id="download" />
                </th>
            </tr>
            </thead>
            <tbody>
            {getFilteredAssetsAttributes(data, stateSortBy, stateSortDirection).map((attr, i) => (
                <tr key={i}>
                    <td>
                        <LimitText limit={30} text={attr.label} textClass="tooltip-right" />
                    </td>
                    {/* <td>{attr.ext}</td>
                            <td>
                                {attr.size
                                    ? filesize(attr.size, {
                                          separator: ',',
                                          base: 10
                                      })
                                    : '-'}
                            </td> */}
                    <td>
                        <LimitText limit={40} text={getFileName(attr.filename)} />
                    </td>
                    <td>
                        <LimitText limit={100} text={attr.description} />
                    </td>
                    <td className="text-center">
                        <a
                            className="btn btn-action btn-link"
                            title="Download"
                            href={attr.src}
                            onClick={() => logDownload(data, user, attr.filename)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i className="icon icon-download" />
                        </a>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default RenderFileList
