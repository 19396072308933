import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Trans, NumberFormat } from '@lingui/react';
import { connect } from 'react-redux';
import format from 'date-fns/format';
import cx from 'classnames';
import { selectors } from '../reducers/appReducer';
import {
    getCodiceArticolo,
    getCodiciInternazionali, getCurrencySymbol,
    getDescrizioneArticolo,
    getInizioValiditaPrezzo,
    getMarcaArticolo, getMisure,
    getPrezzo, getPropLabel,
    getQtaConfenzione,
    getQtaMinimaAcquistabile,
    getStatus,
    getStatusCode,
    getUmImballo,
    getUnitaOrdine
} from './ArticleComponents/ArticleRecap/ArticleRecapUtils';

export class ArticleRecap extends Component {

    render() {

        // const currency = this.props.details?.prezzi[0]?.valuta ? this.props.details?.prezzi[0]?.valuta.value.code : 'EUR'
        // const currencySymbol = currenciesCodeISO[currency].symbol

        //const currencySymbol = getCurrencySymbol(this.props.details?.prezzi[0].valuta ? this.props.details.prezzi[0].valuta : null)

        const currencySymbol = getCurrencySymbol(
            this.props.details?.prezzi?.length ? this.props.details.prezzi[0].valuta : null
        )

        const price = getPrezzo(this.props.details, this.props.priceDecimalPrecision)


        return (
            <table className="table table-striped table-striped-alt table-hover table-article-recap mb-2">
                <tbody>
                    <tr>
                        <td>{getPropLabel('descrizione_marca', this.props.productProperties)}</td>
                        <td>
                            <strong>{getMarcaArticolo(this.props.details)}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>{getPropLabel('codice_articolo', this.props.productProperties)}</td>
                        <td>
                            <strong>{getCodiceArticolo(this.props.details)}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>{getPropLabel('codici_internazionali', this.props.productProperties)}</td>
                        <td>
                            <strong>{getCodiciInternazionali(this.props.details)}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>{getPropLabel('descrizione_articolo', this.props.productProperties)}</td>
                        <td>
                            <strong dangerouslySetInnerHTML={{__html: getDescrizioneArticolo(this.props.details)}} />
                        </td>
                    </tr>
                    <tr>
                        <td>{getPropLabel('prezzo_listino', this.props.productProperties)}</td>
                        <td>
                            {price !== null && (
                                <strong>
                                    <span className="text-error">
                                        {currencySymbol}{' '}
                                        <NumberFormat
                                            value={price}
                                            format={{
                                                minimumFractionDigits:
                                                    this.props.priceDecimalPrecision,
                                                maximumFractionDigits:
                                                    this.props.priceDecimalPrecision,
                                            }}
                                        />{' '}
                                    </span>
                                    {getInizioValiditaPrezzo(this.props.details) !== null && (
                                        <Fragment>
                                            <Trans id="from" />{' '}
                                            {format(getInizioValiditaPrezzo(this.props.details), 'DD/MM/YYYY')}
                                        </Fragment>
                                    )}
                                </strong>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td>{getPropLabel('unita_ordine', this.props.productProperties)}</td>
                        <td>
                            <strong>{getUnitaOrdine(this.props.details)}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>{getPropLabel('quantita_minima_acquistabile', this.props.productProperties)}</td>
                        <td>
                            <strong>{getQtaMinimaAcquistabile(this.props.details)}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>{getPropLabel('unita_contenuta', this.props.productProperties)}</td>
                        <td>
                            <strong>{getUmImballo(this.props.details)}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>{getPropLabel('quantita_contenuta', this.props.productProperties)}</td>
                        <td>
                            <strong>{getQtaConfenzione(this.props.details)}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>{getPropLabel('stato', this.props.productProperties)}</td>
                        <td>
                            <strong
                                className={cx({
                                    'blink text-error': getStatusCode(this.props.details) === 'IV006650',
                                })}
                            >
                                {getStatus(this.props.details)}
                            </strong>
                        </td>
                    </tr>
                    {/*{getMisure(this.props.details, this.props.productProperties)}*/}
                </tbody>
            </table>
        );
    }
}

ArticleRecap.propTypes = {
    item: PropTypes.object,
    details: PropTypes.object,
    language: PropTypes.string,
    productProperties: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        priceDecimalPrecision: selectors.getPriceDecimalPrecision(state),
    };
}

export default connect(mapStateToProps)(ArticleRecap);
