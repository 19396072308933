import { selectors as itemsSelectors } from '../reducers/itemsReducer';
import { selectors as appSelectors } from '../reducers/appReducer';
import { getLocaleFromLanguageCode } from '../intl-helpers';
import * as filterSelectors from './filterSelectors';
import * as constants from '../constants';
import { selectors as sectorsSelectors } from '../reducers/sectorsReducer';
import { selectors as productClassesSelectors } from '../reducers/productClassesReducer';
import { formatAttributeValue } from '../utils/selectors/filterSelectorUtils';
import * as localStorageSelectors from '../selectors/localStorageSelectors';
import { removePreviousStoredTextFullFilter } from '../sagas/localStorageSagas/fullTextFilterSaga';

export const getApiPayload = (state, overrides = {}) => {
    let payload = {
        per_page: itemsSelectors.getPerPage(state),
        page: itemsSelectors.getCurrentPage(state) + 1,
        include_accessori: true,
        include_ricambi: true,
    };

    // Get local storage stored filters from related state
    const storedBrands = localStorageSelectors.getStoredBrands(state)
    const storedStato = localStorageSelectors.getStoredStato(state)
    const storedFuoriCartaceo = localStorageSelectors.getStoredFuoriCartaceo(state)
    const storedLinea = localStorageSelectors.getStoredLinea(state)
    const storedModello = localStorageSelectors.getStoredModello(state)
    const storedSerie = localStorageSelectors.getStoredSerie(state)
    const storedListini = localStorageSelectors.getStoredListini(state)
    const storedSort = localStorageSelectors.getStoredSort(state)
    const storedSortDirection = localStorageSelectors.getStoredSortDirection(state)
    const storedClassification = localStorageSelectors.getStoredClassification(state)
    const storedClassificationPath = localStorageSelectors.getStoredClassificationPath(state)
    const storedEtimClass = localStorageSelectors.getStoredEtimClass(state)
    const storedEtimFeatures = localStorageSelectors.getStoredEtimFeatures(state)
    const storedCustomFilters = localStorageSelectors.getCustomFilters(state)
    const storedFullTextQ = localStorageSelectors.getFullTextQFilters(state)
    const storedFullTextMSearch = localStorageSelectors.getFullTextMSearchFilters(state)


    const languageCode = appSelectors.getLanguage(state);

    payload.locale = getLocaleFromLanguageCode(languageCode);

    const brandFilter = filterSelectors.getFilter(state, constants.BRAND_FILTER);

    // console.log('brandFilter from getApiPayload => ', brandFilter.toJS())
    if (brandFilter.enabled && brandFilter.value.size > 0) {
        payload.sigle_marca = brandFilter.value.map((item) => item.code);
    }

    const statusFilter = filterSelectors.getFilter(state, constants.STATUS_FILTER);

    if (statusFilter.enabled && statusFilter.value.size > 0) {
        payload.stato = statusFilter.value.map((item) => item.value)
    } else if (storedStato !== null) {
        payload.stato = storedStato
    }

    const lineFilter = filterSelectors.getFilter(state, constants.LINE_FILTER);

    if (lineFilter.enabled && lineFilter.value.size > 0) {
        payload.linea = lineFilter.value.map((item) => item.value);
    } else if (storedLinea !== null) {
        payload.linea = storedLinea
    }

    const modelFilter = filterSelectors.getFilter(state, constants.MODEL_FILTER);

    if (modelFilter.enabled && modelFilter.value.size > 0) {
        payload.modello = modelFilter.value.map((item) => item.value);
    } else if (storedModello !== null) {
        payload.modello = storedModello
    }

    // return
    const seriesFilter = filterSelectors.getFilter(state, constants.SERIES_FILTER);

    if (seriesFilter.enabled && seriesFilter.value.size > 0) {
        payload.serie = seriesFilter.value.map((item) => item.value);
    } else if (storedSerie !== null) {
        payload.serie = storedSerie
    }

    const listiniFilter = filterSelectors.getFilter(state, constants.LISTINI_FILTER);

    if (listiniFilter.enabled && listiniFilter.value.size > 0) {
        payload.listini = listiniFilter.value.map((item) => item.value);
    } else if (storedListini !== null) {
        payload.listini = storedListini
    }

    // Custom Filters
    const customFiltersConfig = [
        {
            filter: filterSelectors.getFilter(state, constants.CUSTOM_FILTERS1),
            stored: storedCustomFilters
        },
        {
            filter: filterSelectors.getFilter(state, constants.CUSTOM_FILTERS2),
            stored: storedCustomFilters
        },
        {
            filter: filterSelectors.getFilter(state, constants.CLASSE_CATEGORIA_SCONTO_VENDITA),
            stored: storedCustomFilters
        },
        {
            filter: filterSelectors.getFilter(state, constants.CLASSE_PRODOTTO),
            stored: storedCustomFilters
        },
        {
            filter: filterSelectors.getFilter(state, constants.TIPO_ARTICOLO),
            stored: storedCustomFilters
        },
        {
            filter: filterSelectors.getFilter(state, constants.CATEGORIA_MERCEOLOGICA),
            stored: storedCustomFilters
        }
    ]

    const custom_filters = customFiltersConfig.reduce(
        (filters, { filter, stored }) => processFilter(filter, stored, filters),
        []
    )
    if(custom_filters.length > 0) {
        payload.custom_filters = custom_filters
    }

    // Sort order filter
    const sorting = itemsSelectors.getSorting(state);

    if (sorting.sortBy) {
        payload.sort = sorting.sortBy;
        payload.sort_direction = sorting.sortDirection.toUpperCase();
    }else if (storedSort !== null) {
        payload.sort = storedSort
        payload.sort_direction = storedSortDirection.toUpperCase()
    }

    // const optionals = filterSelectors.getFilter(state, constants.OPTIONALS_FILTER);

    // if (optionals.enabled) {
    //     payload.include_accessori = optionals.value;
    // }

    // const replacementsFlag = filterSelectors.getFilter(state, constants.REPLACEMENTS_FILTER);

    // if (replacementsFlag.enabled) {
    //     payload.include_ricambi = replacementsFlag.value;
    // }

    if(storedClassification !== null){
        payload.product_tree = storedClassification
    }else{
        payload.product_tree = sectorsSelectors.getFilterValue(state);
    }

    if(storedClassificationPath){
        payload.classification_path = storedClassificationPath
    }

    const node = sectorsSelectors.getSelectedTreeItemRecord(state);
    // console.log('node from filtersReducer => ', Record.isRecord(node) ? node.toJS() : node)
    if (node) {
        node.filterParams.forEach((v, k) => {
            payload[k] = v // It's assign a classification_path to payload but somewhere is settings the key
        })
    }

    // Text search, the search of user input string
    // 2 types of text search, the first one with key "q"
    // the second one with key m_search or m_search_locale
    const fullTextFilter = filterSelectors.getFilter(state, constants.FULL_TEXT_FILTER);

    // if (fullTextFilter.enabled && fullTextFilter.value !== '') {
    //     if (fullTextFilter.mode === 'legacy') {
    //         payload.q = fullTextFilter.value;
    //     } else {
    //         payload.m_search = fullTextFilter.value;
    //
    //         if (payload.locale !== 'it_IT') {
    //             payload.m_search_locale = payload.locale;
    //         }
    //     }
    // }
    //console.warn('fullTextFilter mode', fullTextFilter.mode)
    // console.warn('fullTextFilter', fullTextFilter.value)

    if(fullTextFilter.mode === 'legacy' && fullTextFilter.value !== '') {
        payload.q = fullTextFilter.value;
        removePreviousStoredTextFullFilter('q')
    } else if (storedFullTextQ !== null){
        payload.q = storedFullTextQ
    }

    if(fullTextFilter.mode === 'elastic' && fullTextFilter.value !== '') {
        payload.m_search = fullTextFilter.value
        removePreviousStoredTextFullFilter('m_search')
    } else if(storedFullTextMSearch !== null){
        payload.m_search = storedFullTextMSearch
    }

    const productClassFilter = productClassesSelectors.getFilter(state);

    if (productClassFilter.enabled && productClassFilter.value) {
        //console.log('productClassFilter.value.value => ', productClassFilter.value.value)
        payload.etim_class = productClassFilter.value.value;
    }else if(storedEtimClass !== null) {
        payload.etim_class = storedEtimClass
    }

    // Etim features - loading from the localStorage if present
    // console.warn('apiPayload, storedEtimFeatures => ', storedEtimFeatures)
    if(storedEtimFeatures !== null){
        // console.log("storedEtimFeatures =>", storedEtimFeatures)
        payload.etim_features = storedEtimFeatures
    }
    const activeFilters = productClassesSelectors.getActiveFilters(state);
    const attributes = productClassesSelectors.getProductClassAttributes(state);

    const features = activeFilters.map((value, key) => {
        const attribute = attributes.get(key);

        return {
            id: key,
            type: attribute.type,
            value: formatAttributeValue(value, attribute.type),
        };
    });

    if (features.size > 0) {
        payload.etim_features = features.toJS();
    }else{ // TO BE REMOVED THIS ELSE ?
        delete payload.etim_features
    }

    const priceRangeFilter = filterSelectors.getFilter(state, constants.PRICE_RANGE_FILTER);

    if (priceRangeFilter.enabled && priceRangeFilter.value[1] !== 0) {
        payload.prezzo_listino_from = priceRangeFilter.value[0];
        payload.prezzo_listino_to = priceRangeFilter.value[1];
    }

    const fuoriCartaceoFilter = filterSelectors.getFilter(state, constants.FUORI_CARTACEO_FILTER);

    if (fuoriCartaceoFilter.enabled && fuoriCartaceoFilter.value !== null) {
        payload.fuori_listino_cartaceo = fuoriCartaceoFilter.value.value === 'true'
    }else if(storedFuoriCartaceo !== null) {
        payload.fuori_listino_cartaceo = storedFuoriCartaceo
    }

    const esportabilePerCatalogoFilter = filterSelectors.getFilter(
        state,
        constants.ESPORTABILE_PER_CATALOGO_FILTER
    );

    if (esportabilePerCatalogoFilter.enabled && esportabilePerCatalogoFilter.value !== null) {
        payload.export_catalogo =
            esportabilePerCatalogoFilter.value.value === 'true'
    }

    // if (currentHash !== previousHash) {
    //     result.page = 1;
    // }

    // console.log(result);

    return Object.assign({}, payload, overrides);
}


// const addUniqueFilters = (existingFilters, newFilters) => {
//     const uniqueFilters = new Set(existingFilters);
//     newFilters.forEach(filter => uniqueFilters.add(filter));
//     return Array.from(uniqueFilters);
// };

const addUniqueFilters = (existingFilters = [], newFilters = []) => (
    Array.from(new Set([...existingFilters, ...newFilters]))
)

// Funzione per processare un singolo filtro
const processFilter = (currentFilter, storedFilter, existingFilters = []) => {
    if (currentFilter.enabled && currentFilter.value.size > 0) {
        return addUniqueFilters(
            existingFilters,
            currentFilter.value.map(item => item.value)
        )
    }
    return storedFilter !== null
        ? addUniqueFilters(existingFilters, storedFilter)
        : existingFilters;
}
