import React, { Component } from 'react';
// import InfiniteTree from 'react-infinite-tree';
import { withI18n, i18nMark, Trans } from '@lingui/react';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
// import { Record, List, Seq } from 'immutable';
// import TreeUtils from 'immutable-treeutils';
import { toast } from 'react-toastify';
import * as Tabs from '@radix-ui/react-tabs';
import { connect } from 'react-redux';
import { useQuery } from 'react-query';
import { BiCheckbox, BiCheckboxChecked } from 'react-icons/bi';
import cx from 'classnames';
// import includes from 'lodash/includes';
import compact from 'lodash/compact';
import find from 'lodash/find';
import startsWith from 'lodash/startsWith';
import without from 'lodash/without';

import { updateGroup, createGroup, fetchUtenti } from '../api';
import InputField from './form/InputField';
// import SwitchField from './form/SwitchField';
import PropertiesTreeWidget from './utils/PropertiesTreeWidget';
import { SelectBrandsMultiple } from './form/SelectBrandsMultiple';
import { selectors } from '../reducers/sectorsReducer';
import { SelectMultipleField } from './form/SelectMultipleField';
// import SelectField from './form/SelectField';

const validate = (data) => {
    let errors = {};

    if (!data.code) {
        errors.code = i18nMark('validation:error:mandatory:field');
    }

    if (!data.label) {
        errors.label = i18nMark('validation:error:mandatory:field');
    }

    return errors;
};

// export function getCustomClassificationOption(classificationOptions, classificationCode) {
//     const option = find(classificationOptions, { code: 'custom:' + classificationCode });

//     if (!option) {
//         return null;
//     }

//     return {
//         ...option,
//         value: option.value.replace('custom:', ''),
//         label: option.label.it_IT, // TODO: prendere lingua dinamicamente
//     };
// }

export function getSelectedNodesFromGroup(group) {
    if (!group.id) {
        return [];
    }

    const grant = find(group.grants, { code: 'view_properties' });

    if (!grant) {
        return [];
    }

    if (grant.parameters.rules[0].properties.length === 0) {
        return [];
    }

    return compact(grant.parameters.rules[0].properties);
}

export function buildGrantsForApi(data) {
    let grants = [];

    if (data.not_allowed_marca_ids && data.not_allowed_marca_ids.length > 0) {
        grants.push({
            code: 'view_marche',
            enabled: true,
            parameters: {
                not_allowed_marca_ids: data.not_allowed_marca_ids,
            },
        });
    }

    if (data.properties_ids && data.properties_ids.length > 0) {
        let properties = without(compact(data.properties_ids), 'assets', 'asset');

        if (properties.length > 0) {
            if (!properties.includes('assets')) {
                properties = properties.concat('assets');
            }

            if (!properties.includes('asset')) {
                properties = properties.concat('asset');
            }

            grants.push({
                code: 'view_properties',
                enabled: true,
                parameters: {
                    rules: [
                        {
                            type: 'include',
                            target: 'properties',
                            properties,
                        },
                    ],
                },
            });
        }
    }

    if (data.view_product_trees_ids && data.view_product_trees_ids.length > 0) {
        grants.push({
            code: 'view_product_trees',
            enabled: true,
            parameters: {
                product_trees: data.view_product_trees_ids,
            },
        });
    }

    if (data.view_products_ids && data.view_products_ids.length > 0) {
        grants.push({
            code: 'view_products',
            enabled: true,
            parameters: {
                custom_classifications: data.view_products_ids,
            },
        });
    }

    if (data.delete_products) {
        grants.push({
            code: 'delete_products',
            enabled: true,
            parameters: {}, // FIXME: cosa devo inviare qui?
        });
    }

    if(data.everprice_admin || data.everprice_prices){
        const possibleIntegrations = ['everprice_admin', 'everprice_prices']
        const allowedIntegrations = possibleIntegrations.filter(
            (integration) => data[integration] === true
        )

        grants.push({
            code: 'view_integrations',
            enabled: true,
            parameters: {
                "allowed_integrations": allowedIntegrations
            }
        })
    }

    return grants;
}

export function GrantTabs({ formValues, classificationFilter }) {
    const cleanProperties = without(compact(formValues.properties_ids), 'assets');

    return (
        <Tabs.Root className="TabsRoot" orientation="vertical">
            <Tabs.List className="TabsList" aria-label="Seleziona Grant">
                <Tabs.Trigger
                    className={cx('TabsTrigger', {
                        'TabsTrigger-enabled': formValues.not_allowed_marca_ids.length > 0,
                    })}
                    value="view_marche"
                >
                    {formValues.not_allowed_marca_ids.length > 0 ? (
                        <span className="text-primary">
                            <BiCheckboxChecked className="mr-2" size={28} />
                        </span>
                    ) : (
                        <BiCheckbox className="mr-2" size={28} />
                    )}
                    Marche disabilitate
                </Tabs.Trigger>
                <Tabs.Trigger
                    className={cx('TabsTrigger', {
                        'TabsTrigger-enabled': cleanProperties.length > 0,
                    })}
                    value="view_properties"
                >
                    {cleanProperties.length > 0 ? (
                        <span className="text-primary">
                            <BiCheckboxChecked className="mr-2" size={28} />
                        </span>
                    ) : (
                        <BiCheckbox className="mr-2" size={28} />
                    )}
                    Attributi visualizzabili
                </Tabs.Trigger>
                <Tabs.Trigger
                    className={cx('TabsTrigger', {
                        'TabsTrigger-enabled': formValues.view_product_trees_ids.length > 0,
                    })}
                    value="view_product_trees"
                >
                    {formValues.view_product_trees_ids.length > 0 ? (
                        <span className="text-primary">
                            <BiCheckboxChecked className="mr-2" size={28} />
                        </span>
                    ) : (
                        <BiCheckbox className="mr-2" size={28} />
                    )}{' '}
                    Classificazioni visualizzabili
                </Tabs.Trigger>
                <Tabs.Trigger
                    className={cx('TabsTrigger', {
                        'TabsTrigger-enabled': formValues.view_products_ids.length > 0,
                    })}
                    value="view_products"
                >
                    {formValues.view_products_ids.length > 0 ? (
                        <span className="text-primary">
                            <BiCheckboxChecked className="mr-2" size={28} />
                        </span>
                    ) : (
                        <BiCheckbox className="mr-2" size={28} />
                    )}
                    Filtro prodotti
                </Tabs.Trigger>
                <Tabs.Trigger
                    className={cx('TabsTrigger', {
                        'TabsTrigger-enabled': formValues.delete_products === true,
                    })}
                    value="delete_products"
                >
                    {formValues.delete_products === true ? (
                        <span className="text-primary">
                            <BiCheckboxChecked className="mr-2" size={28} />
                        </span>
                    ) : (
                        <BiCheckbox className="mr-2" size={28} />
                    )}
                    Elimina prodotti
                </Tabs.Trigger>

                {/*Grant view_integrations for everprices*/}
                <Tabs.Trigger
                    className={cx('TabsTrigger', {
                        'TabsTrigger-enabled': formValues.view_integrations === true,
                    })}
                    value="view_integrations"
                >
                    {formValues.view_integrations === true ? (
                        <span className="text-primary">
                            <BiCheckboxChecked className="mr-2" size={28} />
                        </span>
                    ) : (
                        <BiCheckbox className="mr-2" size={28} />
                    )}
                    Gestione Everprice
                </Tabs.Trigger>

            </Tabs.List>
            <Tabs.Content className="TabsContent" value="view_marche">
                {/* <Field
                    name="view_marche.enabled"
                    component={SwitchField}
                    placeholder="Attiva grant"
                    layout="horizontal"
                    type="checkbox"
                /> */}
                <h4 className="border-b-2 border-black">Marche disabilitate</h4>
                <Field name="not_allowed_marca_ids">
                    {(props) => (
                        <SelectBrandsMultiple
                            // label="Marche disabilitate"
                            // labelClass="col-3 col-sm-12"
                            inputWrapperClass="col-6 col-sm-12"
                            value={props.input.value}
                            onChange={props.input.onChange}
                        />
                    )}
                </Field>
            </Tabs.Content>
            <Tabs.Content className="TabsContent" value="view_properties">
                {/* <Field
                    name="view_properties.enabled"
                    component={SwitchField}
                    placeholder="Attiva grant"
                    layout="horizontal"
                    type="checkbox"
                /> */}
                <h4 className="border-b-2 border-black">Attributi visualizzabili</h4>
                <div className="col-6 col-sm-12">
                    <Field name="properties_ids">
                        {(props) => {
                            // console.log(props);
                            return (
                                <PropertiesTreeWidget
                                    selectedNodes={props.input.value}
                                    onChange={(properties) => {
                                        // console.log(properties);
                                        props.input.onChange(properties.selectedNodes);
                                        // this.setState({
                                        //     selectedNodes:
                                        //         properties.selectedNodes,
                                        // });
                                    }}
                                />
                            );
                        }}
                    </Field>
                </div>
            </Tabs.Content>
            <Tabs.Content className="TabsContent" value="view_product_trees">
                {/* <Field
                    name="view_product_trees.enabled"
                    component={SwitchField}
                    placeholder="Attiva grant"
                    layout="horizontal"
                    type="checkbox"
                /> */}
                <h4 className="border-b-2 border-black">Classificazioni visualizzabili</h4>
                <Field name="view_product_trees_ids">
                    {(props) => (
                        <SelectMultipleField
                            // label="Classificazioni visualizzabili"
                            options={classificationFilter.options}
                            // labelClass="col-3 col-sm-12"
                            inputWrapperClass="col-6 col-sm-12"
                            value={props.input.value}
                            onChange={props.input.onChange}
                            meta={props.meta}
                        />
                    )}
                </Field>
            </Tabs.Content>
            <Tabs.Content className="TabsContent" value="view_products">
                {/* <Field
                    name="view_products.enabled"
                    component={SwitchField}
                    placeholder="Attiva grant"
                    layout="horizontal"
                    type="checkbox"
                /> */}
                <h4 className="border-b-2 border-black">Filtro prodotti</h4>

                <Field name="view_products_ids">
                    {(props) => {
                        const options = classificationFilter.options
                            .filter((o) => startsWith(o.value, 'custom:'))
                            .map((o) => {
                                return {
                                    ...o,
                                    value: o.value.replace('custom:', ''),
                                    // label: o.label.it_IT,
                                };
                            });

                        return (
                            <SelectMultipleField
                                // label="Classificazioni visualizzabili"
                                options={options}
                                // labelClass="col-3 col-sm-12"
                                inputWrapperClass="col-6 col-sm-12"
                                value={props.input.value}
                                onChange={props.input.onChange}
                                meta={props.meta}
                            />
                        );

                        // return (
                        //     <SelectField
                        //         // label="Classificazioni personalizzate visualizzabili"
                        //         options={options}
                        //         // labelClass="col-3 col-sm-12"
                        //         wrapperClassName="w-full"
                        //         placeholder="Seleziona"
                        //         // value={props.input.value}
                        //         // onChange={props.input.onChange}
                        //         input={props.input}
                        //         meta={props.meta}
                        //     />
                        // );
                    }}
                </Field>
            </Tabs.Content>
            <Tabs.Content className="TabsContent" value="delete_products">
                <h4 className="border-b-2 border-black">Abilita cancellazione prodotti</h4>
                <Field name="delete_products" type="checkbox">
                    {(props) => {
                        return (
                            <div className="form-group">
                                <label className="form-switch">
                                    <input
                                        type="checkbox"
                                        checked={props.input.checked}
                                        onChange={props.input.onChange}
                                    />
                                    <i className="form-icon" />
                                    Si/No
                                </label>
                            </div>
                        );
                    }}
                </Field>
            </Tabs.Content>
            {/* Field everprice_admin*/}
            <Tabs.Content className="TabsContent" value="view_integrations">
                {/*Everprice admin*/}
                <h4 className="border-b-2 border-black">Visualizza il menù “Gestione Everprice”</h4>
                <Field name="everprice_admin" type="checkbox">
                    {(props) => {
                        // console.warn('props.input from everprice', props.input)
                        return (
                            <div className="form-group">
                                <label className="form-switch">
                                    <input
                                        type="checkbox"
                                        checked={props.input.checked}
                                        onChange={props.input.onChange}
                                    />
                                    <i className="form-icon" />
                                    Si/No
                                </label>
                            </div>
                        );
                    }}
                </Field>
                {/*Eveprice prices*/}
                <h4 className="border-b-2 border-black">Dati Everprice</h4>
                <Field name="everprice_prices" type="checkbox">
                    {(props) => {
                        return (
                            <div className="form-group">
                                <label className="form-switch">
                                    <input
                                        type="checkbox"
                                        checked={props.input.checked}
                                        onChange={props.input.onChange}
                                    />
                                    <i className="form-icon" />
                                    Si/No
                                </label>
                            </div>
                        );
                    }}
                </Field>
            </Tabs.Content>

        </Tabs.Root>
    );
}

function GroupsUsersTable({ groupId }) {
    const { data, isLoading } = useQuery(
        ['getUtenti', { group: groupId, page: 1, per_page: 999 }],
        (_, params) => fetchUtenti(params),
        { refetchOnWindowFocus: false }
    );

    if (isLoading) {
        return 'Loading...';
    }

    return (
        <table className="table table-striped table-hover mb-2">
            <thead>
            <tr>
                <th>Nome</th>
                <th>Cognome</th>
                <th>Email</th>
                <th>Ruolo</th>
            </tr>
            </thead>
            <tbody>
            {data.data.results.length === 0 && (
                <tr>
                    <td colSpan={99}>
                        <div className="empty empty-short">
                            <div className="empty-icon">
                                    <i className="icon icon-search" />
                                </div>
                                <p className="empty-title h5">
                                    <Trans id="attention" />
                                </p>
                                <p className="empty-subtitle">
                                    <Trans id="Nessun utente presente nel gruppo" />
                                </p>
                            </div>
                        </td>
                    </tr>
                )}
                {data.data.results.map((u) => {
                    return (
                        <tr key={u.id}>
                            <td>{u.nome}</td>
                            <td>{u.cognome}</td>
                            <td>{u.email}</td>
                            <td>{u.ruolo}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

export class UserGroupEditModal extends Component {
    // tree = null;

    constructor(props) {
        super(props);

        this.state = {
            // isFetching: true,
            // treeData: null,
            // selectedNodes: getSelectedNodesFromGroup(props.group),
            isSubmitting: false,
        };

        // console.log(this.state);
    }

    onSubmit = async (data) => {
        const { id } = this.props.group;
        const { i18n } = this.props;

        const { code, label, ...rest } = data;

        // console.log(data);

        const isUpdating = id !== null;

        this.setState({
            isSubmitting: true,
        });

        const grants = buildGrantsForApi(data);

        const payload = {
            code,
            label,
            grants,
            // rules: [
            //     {
            //         type: 'include',
            //         target: 'properties',
            //         properties,
            //     },
            // ],
        };

        const xhr = isUpdating ? updateGroup(id, payload) : createGroup(payload);

        try {
            await xhr;
        } catch (err) {
            this.setState({
                isSubmitting: false,
            });

            let errorMessage = i18n._('error:unexpected');

            if (err.response) {
                if (err.response.status === 412) {
                    errorMessage = i18n._('fill:required:fields');
                }
            } else if (err.request) {
                errorMessage = i18n._('error:server:generic');
            }

            return {
                [FORM_ERROR]: errorMessage,
            };
        }

        /*if (isUpdating) {
            this.props.updateCollectionSuccess(res);
        } else {
            this.props.fetchCollections();
        }*/

        this.setState({
            isSubmitting: false,
        });

        const msg = isUpdating ? i18n._('userGroup:updated') : i18n._('userGroup:created');

        toast(msg, {
            position: 'bottom-right',
            type: toast.TYPE.SUCCESS,
        });

        this.props.onClose();
    };

    render() {
        const { onClose, group } = this.props;

        // console.log(classificationFilter);

        const isCreating = group.id === null;

        let submit;

        const viewMarcheGrant = find(group.grants, { code: 'view_marche' });
        // const viewPropertiesGrant = find(group.grants, { code: 'view_properties' });
        const viewProductTreesGrant = find(group.grants, { code: 'view_product_trees' });
        const viewProductsGrant = find(group.grants, { code: 'view_products' });

        const everpriceGrants = find(group.grants, { code: 'view_integrations' })

        const everpriceAdminGrant = everpriceGrants
            ? everpriceGrants.parameters.allowed_integrations.includes('everprice_admin')
            : false
        const everpricePricesGrant = everpriceGrants
            ? everpriceGrants.parameters.allowed_integrations.includes('everprice_prices')
            : false

        return (
            <div className="modal active">
                <span className="modal-overlay" aria-label="Close" />
                <div className="modal-container min-h-[90vh] min-w-[90vw]">
                    <div className="modal-header">
                        <div className="modal-title h5">
                            {group.id ? `Modifica "${group.label}"` : 'Crea nuovo gruppo'}
                            <span
                                className="btn btn-clear float-right"
                                aria-label="Close"
                                onClick={onClose}
                            />
                        </div>
                    </div>
                    <div className="modal-body flex-grow">
                        <Tabs.Root className="TabsRoot" defaultValue="general">
                            <Tabs.List className="TabsList" aria-label="Seleziona">
                                <Tabs.Trigger className="TabsTrigger" value="general">
                                    Generale
                                </Tabs.Trigger>
                                <Tabs.Trigger className="TabsTrigger" value="autorizations">
                                    Autorizzazioni
                                </Tabs.Trigger>
                                <Tabs.Trigger className="TabsTrigger" value="users">
                                    Utenti
                                </Tabs.Trigger>
                            </Tabs.List>
                            <Form
                                onSubmit={this.onSubmit}
                                validate={validate}
                                initialValues={{
                                    code: group.code,
                                    label: group.label,
                                    not_allowed_marca_ids:
                                        viewMarcheGrant?.parameters.not_allowed_marca_ids || [],
                                    properties_ids: getSelectedNodesFromGroup(group) || [],
                                    view_product_trees_ids:
                                        viewProductTreesGrant?.parameters.product_trees || [],
                                    view_products_ids:
                                        viewProductsGrant?.parameters.custom_classifications || [],
                                    view_integrations: everpriceGrants?.enabled || false,
                                    everprice_admin: everpriceAdminGrant,
                                    everprice_prices: everpricePricesGrant
                                    // ? getCustomClassificationOption(
                                    //       classificationFilter.options,
                                    //       viewProductsGrant?.parameters
                                    //           .custom_classifications[0]
                                    //   )
                                    // : null,
                                    // view_marche: {
                                    //     enabled: viewMarcheGrant && viewMarcheGrant.enabled,
                                    // },
                                    // view_properties: {
                                    //     enabled: viewPropertiesGrant && viewPropertiesGrant.enabled,
                                    // },
                                    // view_product_trees: {
                                    //     enabled:
                                    //         viewProductTreesGrant && viewProductTreesGrant.enabled,
                                    // },
                                    // view_products: {
                                    //     enabled:
                                    //         viewProductsGrant &&
                                    //         viewProductsGrant.enabled,
                                    // },
                                }}
                                render={({ handleSubmit, form }) => {
                                    // this.form = form;

                                    submit = handleSubmit;

                                    const formValues = form.getState().values;

                                    return (
                                        <form className="form-horizontal" onSubmit={handleSubmit}>
                                            <Tabs.Content className="TabsContent" value="general">
                                                <Field
                                                    name="code"
                                                    component={InputField}
                                                    placeholder={i18nMark('code')}
                                                    className="form-input input-md my-2"
                                                    layout="horizontal"
                                                />
                                                <Field
                                                    name="label"
                                                    component={InputField}
                                                    placeholder={i18nMark('label')}
                                                    className="form-input input-md my-2"
                                                    layout="horizontal"
                                                />
                                            </Tabs.Content>
                                            <Tabs.Content
                                                className="TabsContent"
                                                value="autorizations"
                                            >
                                                <GrantTabs
                                                    formValues={formValues}
                                                    classificationFilter={
                                                        this.props.classificationFilter
                                                    }
                                                />
                                            </Tabs.Content>
                                            <Tabs.Content className="TabsContent" value="users">
                                                <GroupsUsersTable groupId={group.id} />
                                            </Tabs.Content>
                                        </form>
                                    );
                                }}
                            />
                        </Tabs.Root>
                    </div>
                    <div className="modal-footer">
                        <button
                            className="btn btn-primary"
                            onClick={() => submit()}
                            disabled={this.state.isSubmitting}
                        >
                            {isCreating ? (
                                <Trans id="confirm:create" />
                            ) : (
                                <Trans id="confirm:update" />
                            )}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

const UserGroupEditModalWithI18n = withI18n()(UserGroupEditModal);

function mapStateToProps(state) {
    return {
        classificationFilter: selectors.getFilter(state).toJS(),
    };
}

export default connect(mapStateToProps)(UserGroupEditModalWithI18n);
