// Get the state
export const getStoredFiltersSelector = (state) => state.localStorage.userFilters || {};

// Selectors for each filters
export const getStoredBrands = (state) => getStoredFiltersSelector(state).sigle_marca || null;

export const getStoredStato = (state) => getStoredFiltersSelector(state).stato || null;

export const getStoredFuoriCartaceo = (state) =>
    getStoredFiltersSelector(state).fuori_listino_cartaceo || null;

export const getStoredLinea = (state) => getStoredFiltersSelector(state).linea || null;

export const getStoredModello = (state) => getStoredFiltersSelector(state).modello || null;

export const getStoredSerie = (state) => getStoredFiltersSelector(state).serie || null;

export const getStoredListini = (state) => getStoredFiltersSelector(state).listini || null;

export const getStoredSort = (state) => getStoredFiltersSelector(state).sort || null;

export const getStoredSortDirection = (state) =>
    getStoredFiltersSelector(state).sort_direction || null;

export const getStoredClassification = (state) =>
    getStoredFiltersSelector(state).product_tree || null;

export const getStoredClassificationPath = (state) =>
    getStoredFiltersSelector(state).classification_path || null;

export const getStoredEtimClass = (state) => getStoredFiltersSelector(state).etim_class || null;

export const getStoredEtimFeatures = (state) =>
    getStoredFiltersSelector(state).etim_features || null;

export const getCustomFilters = (state) => getStoredFiltersSelector(state).custom_filters || null

export const getFullTextQFilters = (state) => getStoredFiltersSelector(state).q || null

export const getFullTextMSearchFilters = (state) => getStoredFiltersSelector(state).m_search || null