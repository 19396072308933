import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Trans, withI18n } from '@lingui/react';
import { Manager, Reference, Popper } from 'react-popper';
import { FaExpandArrowsAlt, FaTimes, FaPercent } from 'react-icons/fa';
import sortBy from 'lodash/sortBy';
import includes from 'lodash/includes';
import find from 'lodash/find';
import last from 'lodash/last';
import xor from 'lodash/xor';
import isArray from 'lodash/isArray';

import ArticleRecap from './ArticleRecap';
import ArticleDetailsCategoryTab from './ArticleDetailsCategoryTab';
import { getProductImagePath } from '../api';
import { selectors } from '../reducers/appReducer';
import { ARTICLE_DETAIL_SECTIONS } from '../constants';
import {
    getScontoAumento,
    getValidCategories,
    getImgContainerStyle,
    getMarcaArticolo, getCodiceArticolo, getDescrizioneArticolo
} from './ArticleComponents/ArticleDetails/ArticleDetailsBodyUtils';
import ModalScontoAumento from './ArticleComponents/ArticleDetails/ModalScontoAumento';
import ArticlePrintWindow from './ArticleComponents/ArticleDetails/ArticlePrintWindow';

const categories = Object.values(ARTICLE_DETAIL_SECTIONS);
const orderedCategoriesKeys = sortBy(categories, 'order').map((c) => c.key);

export class ArticleDetailsBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeCategories: [...props.initialCategories],
            showPrintWindow: false,
            showPopper: false,
            articleImgPath: null,
        };
    }

    async componentDidMount() {
        const imgPaths = getProductImagePath(this.props.details);

        //console.warn('imgPaths => ', imgPaths)

        if (!isArray(imgPaths)) {
            this.setState({
                articleImgPath: imgPaths,
            });

            return;
        }

        var image = new Image();

        image.onload = () => {
            this.setState({
                articleImgPath: imgPaths[0],
            });
        };

        image.onerror = () => {
            this.setState({
                articleImgPath: imgPaths[1],
            });
        };

        image.src = imgPaths[0];
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.activeCategories.length < this.state.activeCategories.length) {
            this.props.onSelectTab(last(this.state.activeCategories));
        }
    }

    handleTabClick = (key) => {
        this.setState((prevState) => ({
            activeCategories: xor(prevState.activeCategories, [key]),
        }));
    };

    getActiveCategoriesOrdered() {
        return orderedCategoriesKeys.reduce((result, key) => {
            if (includes(this.state.activeCategories, key)) {
                result.push(key);
            }

            return result;
        }, []);
    }

    toggleAllCategories = () => {
        const valid = getValidCategories(this.props.details)

        if (this.state.activeCategories.length !== valid.length) {
            // const keys = categories.map(c => c.key);

            this.setState({
                activeCategories: valid,
            });
        } else {
            this.setState({
                activeCategories: [],
            });
        }
    };

    getCanExpandAll() {
        const valid = getValidCategories(this.props.details)

        return this.state.activeCategories.length !== valid.length;
    }

    getCategory(key) {
        // const { etimClassDetails, productInfoMap } = this.props;

        const category = find(categories, {
            key,
        });

        // if (key !== 'caratteristiche') {
        //     const categoryInfo = productInfoMap[key];

        //     category.info = categoryInfo || {};
        // } else {
        //     category.info = etimClassDetails;
        // }

        return category;
    }

    showPrintWindow = () => {
        this.setState({
            showPrintWindow: true,
        });
    };

    hidePrintWindow = () => {
        this.setState({
            showPrintWindow: false,
        });
    };

    getPrintWindowTitle() {
        return `${getMarcaArticolo(this.props.details)} - ${getCodiceArticolo(this.props.details)} - ${getDescrizioneArticolo(this.props.details)}`;
    }

    togglePopper = () => {
        return this.setState({
            showPopper: !this.state.showPopper,
        });
    };

    render() {
        const {
            article,
            details,
            isPreview,
            productProperties,
            etimClassDetails,
            scrollContainer,
            i18n,
            language,
        } = this.props;

        // console.warn(propductProperties);

        const { activeCategories, showPrintWindow, showPopper } = this.state;

        const canExpandAll = this.getCanExpandAll();

        const shouldShowBottomMargin = window.innerWidth < 640;

        return (
            <Fragment>
                <Manager>
                    {showPrintWindow && (
                        <ArticlePrintWindow
                            title={this.getPrintWindowTitle()}
                            onUnload={this.hidePrintWindow}
                            article={article}
                            details={details}
                            activeCategories={activeCategories}
                            etimClassDetails={etimClassDetails}
                            isPreview={true}
                            />
                    )}
                    <div
                        className="grid grid-cols-12"
                        style={{ marginBottom: shouldShowBottomMargin ? '80px' : '0' }}
                    >
                        <div className="col-span-12 md:col-span-9">
                            <ArticleRecap
                                language={language}
                                item={article}
                                details={details}
                                productProperties={productProperties}
                            />
                        </div>
                        <div className="col-span-12 md:col-span-3" style={{ position: 'relative' }}>
                            <div
                                className="article-details-img-wrapper"
                                style={isPreview ? { height: '235px' } : { maxHeight: '235px' }}
                            >
                                {/* {this.getItemImage(details) ? ( */}
                                {/* {get(details, 'metadata.immagine_prodotto.url') ? ( */}
                                <div style={getImgContainerStyle(this.state.articleImgPath)} />
                                {/* ) : (
                                    <img src={noPhotoImg} style={{ margin: '0 auto' }} alt="" />
                                )} */}
                            </div>
                            {isPreview === false && (
                                <div className="article-ftp-button-holder text-right">
                                    {getScontoAumento(this.props.details) && (
                                        <Reference>
                                            {({ ref }) => (
                                                <button
                                                    onClick={this.togglePopper}
                                                    ref={ref}
                                                    className="btn mr-1"
                                                >
                                                    <FaPercent />
                                                </button>
                                            )}
                                        </Reference>
                                    )}
                                    <button
                                        className={cx('btn tooltip', {
                                            'btn-primary': !canExpandAll,
                                        })}
                                        onClick={this.toggleAllCategories}
                                        data-tooltip={
                                            canExpandAll
                                                ? i18n._('sections:open:all')
                                                : i18n._('sections:close:all')
                                        }
                                    >
                                        {canExpandAll ? (
                                            <FaExpandArrowsAlt style={{ verticalAlign: '-10%' }} />
                                        ) : (
                                            <FaTimes style={{ verticalAlign: '-10%' }} />
                                        )}
                                    </button>{' '}
                                    <button
                                        className="btn btn-success"
                                        onClick={this.showPrintWindow}
                                    >
                                        <i className="icon icon-download" />{' '}
                                        <span className="text-uppercase">
                                            <Trans id="Pdf Sheet" />
                                        </span>
                                    </button>
                                </div>
                            )}
                        </div>
                        {/* {isPreview === false && ( */}
                        <ArticleDetailsCategoryTab
                            onTabClick={this.handleTabClick}
                            activeCategories={activeCategories}
                            details={details}
                            scrollOnClick={true}
                            scrollContainer={scrollContainer}
                            productProperties={productProperties}
                            etimClassDetails={etimClassDetails}
                            item={article}
                            language={language}
                        />
                        {/* )} */}
                        {/* <div className="col-span-12">
                            <ErrorBoundary>
                                {this.getActiveCategoriesOrdered().map((key) => {
                                    const category = this.getCategory(key);

                                    return (
                                        <Element key={key} name={`category-${key}-${details.id}`}>
                                            <ItemDataCategory
                                                productProperties={productProperties}
                                                etimClassDetails={etimClassDetails}
                                                item={article}
                                                details={details}
                                                category={category}
                                                language={language}
                                            />
                                        </Element>
                                    );
                                })}
                            </ErrorBoundary>
                        </div> */}
                    </div>
                    <Popper
                        // innerRef={(node) => this.popperNode = node}
                        placement="top"
                        // modifiers={[{ name: 'arrow' }]}
                        strategy="fixed"
                        modifiers={[
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 8],
                                },
                            },
                        ]}
                    >
                        {(props) => {
                            return showPopper ? (
                                <div
                                    ref={props.ref}
                                    style={props.style}
                                    data-placement={props.placement}
                                >
                                    <ModalScontoAumento
                                        onClose={this.togglePopper}
                                        details={this.props.details}
                                    />
                                    {/* <div data-popper-arrow className="popper-arrow" ref={props.arrowProps.ref} style={props.arrowProps.style} /> */}
                                </div>
                            ) : null;
                        }}
                    </Popper>
                </Manager>
            </Fragment>
        );
    }
}

ArticleDetailsBody.defaultProps = {
    onSelectTab: () => {},
};

const mapStateToProps = (state, ownProps) => {
    return {
        productProperties: selectors.getProductProperties(state),
        language: selectors.getLanguage(state),
    };
};

const ConnectedArticleDetailsBody = connect(mapStateToProps)(ArticleDetailsBody);

export default withI18n()(ConnectedArticleDetailsBody);
